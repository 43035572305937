<template>
  <div class="mc-pay" v-if="visible">
    <div class="pay-banner" :shown="animationFlag">
      <div class="back-btn" @click="$emit('onClose')"><img src="@/assets/back.png"></div>
    </div>
    <div class="pay-content" :shown="animationFlag">
      <div class="pay-icon"></div>
      <div class="pay-tip">二维码每30秒自动更新</div>
      <div class="pay-qr-code">
        <img :src="qrCodeSrc" alt="支付码" />
      </div>
      <div class="pay-card">
        <div class="pay-card-shower">
          <img src="./images/card.png" />
        </div>
        <div class="pay-card-list">
          <a-dropdown>
            <span @click="(e) => e.preventDefault()">
              <span style="font-size: 10px">金点</span> 100
              <a-icon type="down" />
            </span>
            <!-- <a-menu slot="overlay">
              <a-menu-item>￥ 50 </a-menu-item>
              <a-menu-item>￥ 100 </a-menu-item>
              <a-menu-item> ￥ 200 </a-menu-item>
            </a-menu> -->
          </a-dropdown>
        </div>
      </div>
      <div class="pay-tip">
        以上余额截止<span>{{ time }}</span>
      </div>
      <!-- <div class="pay-recharge" @click="payDrawerVisible = true">立即充值</div> -->
    </div>
  </div>
</template>
<script>
import { dateFormat } from "@/helper/index";

export default {
  name: "Pay",
  data() {
    return {
      animationFlag: false,
      time: dateFormat(new Date()),
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    qrCodeSrc: {
      type: String,
      default: "/mc/api/user/card/defaultQRCode",
    },
  },
  watch: {
    qrCodeSrc() {
      this.time = dateFormat(new Date());
    },
    visible(v) {
      setTimeout(() => {
        this.animationFlag = !!v;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mc-pay {
  z-index: 999;
  font-weight: 400;
  .pay-banner {
    position: fixed;
    top: -1.53rem;
    width: 100%;
    height: 1.53rem;
    background-image: url(./images/bg.png);
    transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    @include common-bg;
    .anticon {

    }
    .back-btn{
      position: absolute;
      top: 0.2rem;
      left: 0.15rem;
      width: 0.17rem;
      height: 0.17rem;
      float: left;
      overflow: hidden;
      line-height: initial;
      img{
        width: 100%;
        height: 100%;
      }
    }
    &[shown] {
      top: 0;
    }
  }
  .pay-content {
    width: 100%;
    height: calc(100vh - 1.3rem);
    position: fixed;
    top: 102%;
    background-color: #fff;
    box-shadow: $main-box-shadow;
    padding-top: 0.3rem;
    transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    &[shown] {
      top: 1.3rem;
    }
    .pay-recharge {
      font-weight: 500;
      color: #111;
      font-size: 0.14rem;
      text-align: center;
      margin-top: 0.08rem;
    }
    .pay-card {
      margin: 0 auto;
      display: flex;
      width: max-content;
      margin-bottom: 0.05rem;
      .pay-card-shower {
        img {
          width: 0.36rem;
          height: 0.24rem;
        }
      }
      .pay-card-list {
        margin-left: 0.08rem;
        border-bottom: 1px solid #979797;
        ::v-deep {
          .anticon {
            display: none;
          }
        }
      }
    }
    .pay-qr-code {
      width: 1.75rem;
      height: 1.75rem;
      margin: 0.14rem auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .pay-tip {
      font-size: 0.1rem;
      color: #999;
      text-align: center;
    }
    .pay-icon {
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      background-image: url(./images/icon.png);
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center;
      border-radius: 0.5rem;
      overflow: hidden;
      top: -0.25rem;
      background-color: #fff;
      margin: auto;
      left: 0;
      right: 0;
    }
  }
}

@keyframes top-half {
  0% {
    top: -1.53rem;
  }
  100% {
    top: 0;
  }
}
@keyframes bottom-half {
  0% {
    top: 102%;
  }
  100% {
    top: 1.3rem;
  }
}
</style>
