<template>
  <div class="nav-footer" :shown="shown">
    <div class="nav-footer-container">
      <div
        class="nav-footer-item left"
        :current="mode === 'CARD'"
        @click="goCard"
      >
        <img
          :src="
            mode === 'CARD'
              ? require('./images/icon_card_sel.png')
              : require('./images/icon_card.png')
          "
        />
        <br />卡片
      </div>
      <div class="nav-footer-item right" :current="mode === 'MY'" @click="goMy">
        <img
          :src="
            mode === 'MY'
              ? require('./images/icon_my_sel.png')
              : require('./images/icon_my.png')
          "
        />
        <br />我的
      </div>
    </div>
    <div class="qr-code">
      <div class="qr-code-content" @click="open"></div>
    </div>
    <Pay
      :qrCodeSrc="qrCodeSrc"
      :visible="showPay"
      @onClose="
        showPay = false;
        cardNo = '';
      "
    ></Pay>
  </div>
</template>
<script>
import Pay from "@/components/Pay";
import { getMyCards } from "@/service";
export default {
  name: "NavFooter",
  components: { Pay },
  data() {
    return {
      mode: "MY",
      showPay: false,
      cardNo: "",
      timestamp: new Date().getTime(),
      timer: null,
    };
  },
  props: {
    shown: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.mode = this.$route.name === "My" ? "MY" : "CARD";
    this.$eventBus.$on("showPay", (cardNo) => {
      this.cardNo = cardNo;
      this.handleShowPay();
    });
    this.timer = setInterval(() => {
      this.timestamp = new Date().getTime();
    }, 30000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  computed: {
    qrCodeSrc() {
      const merchantId = this.$store.state.merchantId;
      return this.cardNo
        ? `/mc/api/user/card/QRCode?cardNo=${this.cardNo}&timestamp=${this.timestamp}`
        : `/mc/api/user/card/defaultQRCode?merchantId=${merchantId}&timestamp=${this.timestamp}`;
    },
  },
  methods: {
    handleShowPay() {
      this.showPay = true;
    },
    goCard() {
      this.$router.push({ name: "VipViewer" });
    },
    goMy() {
      this.$router.push({ name: "My" });
    },
    open() {
      this.getMyCards();
    },
    async getMyCards() {
      const merchantId = this.$store.state.merchantId;
      try {
        const { data: cardList } = await getMyCards(merchantId);
        if (cardList && !!cardList.length) {
          let getUseful = false;
          for (let item of cardList) {
            if (item.status === 4) {
              getUseful = true;
              break;
            }
          }
          if (getUseful) {
            this.showPay = true;
          } else {
            this.$message.error("您的用户权益卡尚未激活!");
          }
        } else {
          this.$message.error("请先绑定用户权益卡!");
          this.$router.push({
            name: "VipViewer",
            params: {
              tab: "myCard",
            },
          });
        }
      } catch (error) {
        //todo
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/variable.scss";
.nav-footer {
  position: fixed;
  bottom: -1.2rem;
  height: 0.56rem;
  width: 100%;
  z-index: 999;

  &[shown] {
    bottom: 0;
  }
  .nav-footer-container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    .nav-footer-item {
      height: 100%;
      text-align: center;
      background-color: #fff;
      font-size: 0.11rem;
      width: calc(50% - 0.29rem);
      img {
        width: 0.24rem;
        margin: 0.05rem 0 0.03rem;
      }
      &.left {
        box-shadow: 0 4px 14px rgba(18, 18, 18, 0.2);
        border-top-right-radius: 0.18rem;
      }
      &.right {
        box-shadow: 0 4px 14px rgba(18, 18, 18, 0.2);
        border-top-left-radius: 0.18rem;
      }
      .iconfont {
        font-size: 0.24rem;
      }
      &[current] {
        color: $active-color;
      }
    }
  }
  .qr-code {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("./images/bg_circular3.png");
    width: 0.6rem;
    height: 0.56rem;
    background-color: transparent;
    @include common-bg;
    background-position: center 0.05rem;
    .qr-code-content {
      width: 0.5rem;
      height: 0.5rem;
      background-color: $active-color;
      background-image: url("./images/qrCode.png");
      border-radius: 0.5rem;
      z-index: 999;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      background-repeat: no-repeat;
      background-size: 0.2rem 0.2rem;
      background-position: center;
      top: -0.25rem;
    }
  }
}
</style>